import React from 'react';

import Layout from 'components/layout';

import styles from './wedding-page.module.scss';

export default () => (
  <Layout>
    <div className={styles.infoGroup}>
      <h4>Location</h4>
      <a href='https://goo.gl/maps/DB6ozXjrDWL2'>
        <p>331 Huber Road</p>
        <p>Livingston Manor, NY 12758</p>
      </a>
    </div>
    <div className={styles.infoGroup}>
      <h4>Saturday, June 15th</h4>
      <p>Ceremony and festivities</p>
      <p>2:30pm</p>
    </div>
    <div className={styles.infoGroup}>
      <h4>Attire</h4>
      <p>
        Country (not cowboy) chic! The wedding is at the family farm, so you should wear shoes in which you'll feel comfortable <b>walking on grass</b> <em>(Hint hint, give the stilettos a day off).</em>
        &nbsp;Also, since we'll be up on a mountain, it might get a little chilly, especially at night.
      </p>
    </div>
    <hr />
    <div className={styles.infoGroup}>
      <h4>Sunday, June 16th</h4>
      <p>Join us back at the barn for some breakfast before you hit the road.</p>
      <p>9:30am - 12:00pm</p>
    </div>
    <hr />
    <div className={styles.infoGroup}>
      <h4>About the Venue</h4>
      <p>
        Claremont Farm, named for the horse stable the Novograd family operated in Manhattan beginning in 1942, is our family's farm located in the Catskills. The barn you
        see on this website and your invitations is a true-to-form sketch of the one where we'll be, toasting, feasting, and boogie-ing on the 15th!
      </p>
      <p className={styles.spaced}>
        We're so excited to be able to share such a special place with all the people we love, especially on such a special occasion. We suggest taking some time to enjoy the
        scenery and beauty of the surrounding area if you have the time.
      </p>
    </div>
  </Layout>
)
